<template>
	<div class="content">
		<WithLeftMenu :blocks="withLeftMenuContent"></WithLeftMenu>

		<section class="fullPageSectionContainer setMenuColor newForm" data-menu-color="light">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #262E39"></div>
				<Feedback></Feedback>
			</div>
		</section>
	</div>
</template>

<script>
	import	WithLeftMenu from '@/components/app/WithLeftMenu'

	import reviewsData from "@/assets/json/reviews.json";

	export default {
		metaInfo: {
			title: 'О веб-студии Артрокетс',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Информация о компании Артрокетс. Основная компетенция веб-студии – создание и дальнейшее развитие сайтов и интернет-магазинов.' },
				{ vmid: 'og:title', property: 'og:title', content: 'О веб-студии Артрокетс' },
				{ vmid: 'og:description', property: 'og:description', content: 'Информация о компании Артрокетс. Основная компетенция веб-студии – создание и дальнейшее развитие сайтов и интернет-магазинов.' },
			],
		},
		data: () => ({
			withLeftMenuContent: [
				{
					component: 'aboutUs',
					name: 'О компании',
					data: [
						{
							component: 'aboutUsInNumbers',
							additionalClass: 'margin4',
							data: [
								{
									title: '50+',
									text: 'уникальных проектов разработано «под<br> ключ»'
								},{
									title: '20',
									text: 'сайтов на регулярной поддержке и продвижении'
								},{
									title: '18',
									text: 'профильных специалистов в<br> команде'
								},{
									title: '3',
									text: 'года стабильной<br> работы на рынке<br> веб-услуг'
								}
							]
						},{
							component: 'image',
							image: require(`@/assets/img/command2.jpg`),
							additionalClass: 'margin8'
						},{
							component: 'text',
							text: `Основная компетенция нашей веб-студии: Это создание и дальнейшее развитие сайтов и интернет-магазинов. С нами работают разные компании: федеральные страховые компании, региональные банки, порталы недвижимости, медицинские центры, онлайн-магазины косметики, сложного технического оборудования и <router-link to="/portfolio/" class="redLink">многие другие</router-link>.`,
							additionalClass: 'margin2 lh28'
						},{
							component: 'rating',
							data: [
								{
									image: require(`@/assets/img/rating/1.png`),
									list: [
										{
											title: '5-ое место',
											text: 'Среди ведущих разработчиков сайтов 2019 год г. Казань Рейтинг Рунета'
										},{
											title: '3-место',
											text: 'Среди ведущих разработчиков интернет-магазинов 2019 год г. Казань Рейтинг Рунета'
										}
									]
								},{
									image: require(`@/assets/img/rating/2.png`),
									list: [
										{
											title: 'Топ-10 ведущих студий',
											text: '2019 год г. Казань рейтинг CMSMagazine'
										}
									]
								},{
									image: require(`@/assets/img/rating/3.png`),
									list: [
										{
											title: 'Топ-10 лучших сертифицированных партнеров ',
											text: '2019 год г. Казань рейтинг 1С-Битрикс'
										}
									]
								},{
									image: require(`@/assets/img/rating/4.png`),
									list: [
										{
											title: '2-ое место',
											text: 'В номинации «Сайт банка или страховой компании» 2019 год Россия «Золотой сайт»'
										}
									]
								},{
									image: require(`@/assets/img/rating/5.png`),
									list: [
										{
											title: 'Золотой сертифицированный партнер',
											text: 'с 2017 года – по наст.время CMS 1С-Битрикс'
										}
									]
								}
							]
						}
					]
				},{
					component: 'command',
					name: 'Наша команда',
					data: [
						{
							image: require(`@/assets/img/command/1.jpg`),
							name: 'Булат',
							position: 'директор'
						},{
							image: require(`@/assets/img/command/8.jpg`),
							name: 'Наиль',
							position: 'арт-директор'
						},{
							image: require(`@/assets/img/command/2.jpg`),
							name: 'Айгуль',
							position: 'руководитель отдела SEO'
						},{
							image: require(`@/assets/img/command/9.jpg`),
							name: 'Алексей',
							position: 'менеджер проектов'
						},{
							image: require(`@/assets/img/command/4.jpg`),
							name: 'Султан',
							position: 'backend разработчик'
						},{
							image: require(`@/assets/img/command/5.jpg`),
							name: 'Руслан',
							position: 'fullstack разработчик'
						},{
							image: require(`@/assets/img/command/6.jpg`),
							name: 'Арсений',
							position: 'web дизайнер'
						},{
							image: require(`@/assets/img/command/7.jpg`),
							name: 'Игорь',
							position: 'frontend разработчик'
						}/*,{
							image: require(`@/assets/img/command/notImage.jpg`),
							name: 'Марсель',
							position: 'SEO специалист'
						}*/,{
							image: require(`@/assets/img/command/10.jpg`),
							name: 'Анна',
							position: 'копирайтер'
						},{
							image: require(`@/assets/img/command/3.jpg`),
							name: 'Айрат',
							position: 'менеджер проектов'
						}
					]
				},{
					component: 'instagram',
					name: 'Следите за нами в Instagram',
					linkText: 'Подписаться',
					linkHref: 'https://www.instagram.com/artrockets.ru/',
					linkTarget: "_blank"
				},{
					component: 'reviews',
					name: 'Отзывы',
					data: reviewsData
				}
			]
		}),
		name: 'about',
		components: {
			WithLeftMenu,
			Feedback: () => import('@/components/new/Forms/Feedback'),
		}
	}
</script>
